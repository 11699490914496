
<script>
  import { onMount } from 'svelte';
  import { initializeApp } from 'firebase/app';
  import {
    getStorage,
    ref as storageRef,
    uploadBytesResumable,
    getDownloadURL,
    deleteObject
  } from 'firebase/storage';
  import {
    getDatabase,
    ref as dbRef,
    onValue,
    set,
    remove
  } from 'firebase/database';
  import {
    getAuth,
    GoogleAuthProvider,
    GithubAuthProvider,
    signInWithPopup,
    signOut,
    onAuthStateChanged
  } from 'firebase/auth';


    const firebaseConfig = {
      apiKey: "AIzaSyAAgLvjnRHIGaPbCBOCwdlnSbwUL3AO3to",
      authDomain: "ilostmyipad.firebaseapp.com",
      databaseURL: "https://ilostmyipad-default-rtdb.firebaseio.com",
      projectId: "ilostmyipad",
      storageBucket: "ilostmyipad.appspot.com",
      messagingSenderId: "1096571522428",
      appId: "1:1096571522428:web:80c118b02e9255266928f9",
      measurementId: "G-W353NL1TTB"
    };

  const app = initializeApp(firebaseConfig);
  const storage = getStorage(app);
  const db = getDatabase(app);
  const auth = getAuth(app);

  const googleProvider = new GoogleAuthProvider();
  const githubProvider = new GithubAuthProvider();

  let user = null;
  let images = [];

  onAuthStateChanged(auth, (loggedInUser) => {
    user = loggedInUser;
  });

  async function signInWithGoogle() {
    try {
      const result = await signInWithPopup(auth, googleProvider);
      user = result.user;
    } catch (error) {
      console.error(error);
    }
  }

  async function signInWithGitHub() {
    try {
      const result = await signInWithPopup(auth, githubProvider);
      user = result.user;
    } catch (error) {
      console.error(error);
    }
  }

  function signOutUser() {
    signOut(auth)
      .then(() => {
        user = null;
      })
      .catch((error) => {
        console.error(error);
      });
  }

  const imagesRef = dbRef(db, 'images');
  onValue(imagesRef, (snapshot) => {
    const data = snapshot.val();
    if (data) {
      images = Object.keys(data).map(key => ({
        id: key,
        url: data[key].url,
        message: data[key].message || '',
        flipped: data[key].flipped || false,
        glow: data[key].message ? getRandomNeonColor() : null
      }));
    } else {
      images = [];
    }
  });

  async function writeMessage(imageId, message) {
    try {
      await set(dbRef(db, `images/${imageId}/message`), message);
    } catch (error) {
      console.error(error);
    }
  }

  async function uploadImage(event) {
    const file = event.target.files[0];
    if (!file) return;

    const imageId = Date.now().toString();

    try {
      const imgStorageRef = storageRef(storage, `images/${imageId}`);
      const uploadTask = uploadBytesResumable(imgStorageRef, file);

      uploadTask.on('state_changed',
        null,
        (error) => {
          console.error('Error uploading image:', error);
        },
        async () => {
          const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
          await set(dbRef(db, `images/${imageId}`), {
            url: downloadURL,
            message: '',
            flipped: false
          });
        }
      );
    } catch (error) {
      console.error('Error uploading image:', error);
    }
  }

  async function deleteImage(imageId) {
    try {
      const imageToDelete = images.find(img => img.id === imageId);
      if (!imageToDelete) return;

      const imageUrl = imageToDelete.url;
      await remove(dbRef(db, `images/${imageId}`));
      const imgStorageRef = storageRef(storage, `images/${imageId}`);
      await deleteObject(imgStorageRef);

      images = images.filter(img => img.id !== imageId);
    } catch (error) {
      console.error('Error deleting image:', error);
    }
  }

  function flipCard(imageId) {
    images = images.map(img => {
      if (img.id === imageId) {
        img.flipped = !img.flipped;
        img.glow = null;
      }
      return img;
    });
  }

  function getRandomNeonColor() {
    const neonColors = [
      'rgba(0, 87, 38, 1)',
      'rgba(0, 87, 38, 1)',
      'rgba(0, 87, 38, 1)',
      'rgba(0, 87, 38, 1)',
      'rgba(0, 87, 38, 1)'
    ];
    return neonColors[Math.floor(Math.random() * neonColors.length)];
  }

  onMount(() => {
    images.forEach(async (image) => {
      image.url = await getDownloadURL(storageRef(storage, image.url));
    });
  });
</script>

<style>
  main {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
  }

  .navbar {
    width: 100%;
    background-image: url('/free.jpg');
    color: #fff;
    display: flex;
    justify-content: space-between;
    padding: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
    align-items: center;
  }

  .navbar a {
    color: #fff;
    text-decoration: none;
    padding: 10px 15px;
    display: block;
    font-size: 14px;
  }
    
  .logo-container {
    display: flex;
    align-items: center;
  }

  .logo {
    height: 100px;
    width: 100px;
    margin-right: 10px;
    pointer-events: none;
    border-radius: 50%;
  }

  .file-input {
    display: none;
  }

  .google-button,
  .byebye-button,
  .github-button {
    background-color: #007bff;
    color: #fff;
    padding: 0.3em 0.7em;
    text-decoration: none;
    text-align: center;
    font-weight: bold;
    cursor: pointer;
    margin-left: 5px;
    border-radius: 3px;
  }

  .google-button:hover,
  .byebye-button:hover,
  .github-button:hover {
    background-color: #0056b3;
  }

  .center-buttons {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-top: 5px;
  }

  .center-buttons button {
    margin-top: 5px;
  }

  .card-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 10px;
    justify-items: center;
    width: 100%;
    max-width: 1000px;
    margin-top: 180px;
    margin-bottom: 60px;
  }

  .card {
    width: 300px;
    height: 300px;
    perspective: 800px;
    position: relative;
    margin: 5px;
  }

  .card .front,
  .card .back {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    transition: transform 0.6s;
    transform-style: preserve-3d;
    backface-visibility: hidden;
    border-radius: 50%;
    overflow: hidden;
  }

  .card .front {
    z-index: 2;
    transform: rotateY(0deg);
  }

  .card .back {
    transform: rotateY(180deg);
    padding: 5px;
    background-image: url('/free.jpg');
    text-align: left;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .card.flip .front {
    transform: rotateY(-180deg);
  }

  .card.flip .back {
    transform: rotateY(0deg);
  }

  .card img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
  }

  .card.glow .front,
  .card.glow .back {
    box-shadow: 0 0 30px var(--glow-color);
  }

  .card .back textarea {
    width: calc(100% - 10px);
    max-width: 100%;
    height: calc(100% - 20px);
    max-height: 100%;
    padding: 5px;
    margin: 3px;
    border: none;
    resize: none;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
    box-sizing: border-box;
    background-color: inherit;
    color: inherit;
  }

  .message-container {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .message {
    font-family: monospace;
    background-color: black;
    color: white;
    padding: 3px;
    margin-bottom: 3px;
    border-radius: 3px;
    white-space: pre-wrap;
    text-align: left;
    font-size: 12px;
  }

  .google-button,
  .github-button,
  .byebye-button {
    display: inline-block;
    padding: 0.3em 0.7em;
    text-decoration: none;
    text-align: center;
    font-weight: bold;
    cursor: pointer;
    border-radius: 3px;
  }

  .google-button {
    background-color: green;
    color: white;
  }

  .google-button:hover {
    background-color: #fff;
    color: green;
  }

  .byebye-button {
    background-color: red;
    color: white;
    margin-left: auto;
  }

  .byebye-button:hover {
    background-color: #fff;
    color: red;
  }

  .delete-button {
    background-color: #ff6347;
    color: white;
    border: none;
    padding: 3px 6px;
    cursor: pointer;
    margin-top: 3px;
    display: inline-block;
    font-size: 12px;
  }

  .delete-button:hover {
    background-color: #d63429;
  }

  .delete-checkbox {
    display: none;
  }

  .delete-label {
    cursor: pointer;
    font-size: 12px;
  }

  .footer {
    width: 100%;
    background-image: url('/free.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-color: rgba(240, 240, 240, 0.5);
    color: #fff;
    text-align: center;
    padding: 5px 10px;
    box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    position: fixed;
    bottom: 0;
    left: 0;
    font-size: 8px;
  }
</style>

<main>
  <div class="navbar">
    <div class="logo-container">
      <a href="/">
        <img class="logo" src="logo.jpg" alt="Logo">
      </a>
      {#if user}
        <label for="file-input" class="google-button">upload</label>
        <input id="file-input" type="file" accept="image/*" class="file-input" on:change={uploadImage}>
        <button class="byebye-button" on:click={signOutUser}>Sign Out</button>
      {:else}
        <button class="google-button" on:click={signInWithGoogle}>Google</button>
       
      {/if}
    </div>
  </div>

  <div class="card-grid">
    {#each images as image}
      <div class="card {image.glow ? 'glow' : ''}" class:flip={image.flipped} on:click={() => flipCard(image.id)} style="--glow-color: {image.glow}">
        <div class="front">
          <img src={image.url} alt="Image" />
          {#if user}
            <label for="delete-checkbox-{image.id}" class="delete-label">Delete</label>
            <input id="delete-checkbox-{image.id}" type="checkbox" class="delete-checkbox" on:change={() => deleteImage(image.id)}>
          {/if}
        </div>
        <div class="back">
          {#if user}
            <textarea rows="3" bind:value={image.message} on:click|stopPropagation on:input|stopPropagation></textarea>
            <button on:click|stopPropagation={() => writeMessage(image.id, image.message)}>Save Message</button>
            <button class="delete-button" on:click|stopPropagation={() => deleteImage(image.id)}>Delete</button>
          {/if}
          <div class="message-container">
            {#if image.message}
              <div class="message">{image.message}</div>
            {/if}
          </div>
        </div>
      </div>
    {/each}
  </div>

  <!-- Footer -->
  <div class="footer">
    <p>made with&nbsp;&#10084;&nbsp;JesseJames.web.app&nbsp;</p>
  </div>
</main>
